import { getUrlParam, storage } from "../utils";
import { proxy } from "./proxy";

export default {
	// 根地址
	host: proxy["/dev"].target,

	// 请求地址
	get baseUrl() {
		let proxy = getUrlParam("proxy");

		if (proxy) {
			storage.set("proxy", proxy);
		} else {
			proxy = storage.get("proxy") || "dev";
		}

		return "http://localhost:20001/admin";
		// return "http://172.16.0.46:20001/admin";
		return "http://172.31.1.253:18199/admin";
		// return "http://shop.api.dev.com/admin";
		//return "https://shop.api.suwa3d.com/admin";
		//  return "http://localhost:20001/admin";
		// return "https://gray.shop.api.suwa3d.com/admin";
		// return "http://sharewow3d.com:18199/admin";
		// return "https://mp.api.suwa3d.com/admin";
		// if (!isDev) {
		// 	return "https://mp.api.SRUIDD3d.com/admin";
		// }

		return `/${proxy}`;
	},
	oss: {
		website: "https://SRUIDD3d-test.oss-cn-shanghai.aliyuncs.com",
		"3dview": "https://SRUIDD3d-test.oss-cn-shanghai.aliyuncs.com",
		webwechat: "https://SRUIDD3d-test.oss-cn-shanghai.aliyuncs.com",
		securedata: "https://SRUIDD3d-test.oss-cn-shanghai.aliyuncs.com",
		shop: "https://suwa3d-test.oss-cn-shanghai.aliyuncs.com"
	},

	weightWs: "ws://localhost:20001/ws",
	wechatApi: "http://127.0.0.1:8299",

	msgWebsocket: {
		WEBSOCKETADDRESS: "ws://172.31.1.254:20001"
	},
	menuIgnore: [],
	watermask: {
		title: "速哇3D真人手办"
	}
};
